import React from 'react';
import {Row, Col, Card, Image } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
// import logo from '../../assets/logo/logo.webp';
import { styles } from './styles';
import { Link } from 'react-router-dom';
import { MENU_CATEGORIES, LOGO_URL } from '../../utils/constant';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';

const gridStyle: React.CSSProperties = {
    width: '25%',
    textAlign: 'center',
};

interface categoryInterface {
    label: string;
    value: string;
}

export default function AboutUs() {
    const matches = useMediaQuery('(min-width: 768px)');

    return (
        <Card title='About Us' style={matches ? { padding: '20px' }:{}}>
            <Row gutter={24} style={styles.logo}>
                <Col  span={16}  xs={24} sm={12} md={8} lg={6}>
                    <Image width={130} src={LOGO_URL} style={{background:'#95100d'}} />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='What is Sportsfeed24?' style={styles.card}>
                        <Card.Meta
                        title="An open and known platform for live sports and soccer and free live-streaming."
                        />
                        <Paragraph style={styles.paragraph}>
                        Welcome to SportsFeed24, your ultimate destination for uninterrupted online sports streaming! Say goodbye to interruptions and hello to non-stop action. Whether you are a fan of football/soccer, NBA, NFL, boxing, rugby, UFC, Formula 1, tennis, or cricket, we have got you covered. Our platform offers free streaming services, ensuring you never miss a moment of your favorite games. With SportsFeed24, enjoy live sports right at your fingertips, all without worrying about damaging your electronics. Join us today and experience the safest and most secure way to watch sports from the comfort of your own home.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Who are we? Know more about us below!' style={styles.card}>
                        <Card.Meta
                        title="A platform to watch Live soccers streams"
                        />
                        <Paragraph style={styles.paragraph}>
                            Our mission is to provide the best solutions for our customers. Users can experience free live sports streaming service.
                        </Paragraph>
                        <Paragraph>
                            SportsFeed24 is our streaming service that has been entertaining sports enthusiasts for a year now 
                            and will continue to do so for many more years to come. Our service is our pride because of its 
                            high-quality streaming and user satisfaction. SportsFeed24 is a highly trusted platform where you 
                            can watch live football, NBA, NHL, UFC, cricket, Formula 1, and many more.
                        </Paragraph>
                        <Paragraph>
                        Simply put, our all-in-one sports service offers a wide range of sports for you to enjoy from the comfort of your own space. Say goodbye to the time-consuming and stressful task of scouring multiple sites for your favorite sports we have built this site specifically for you to stream live sports effortlessly.
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='How can Sportsfeed24 helps you watch uninterrupted live sports for free?' style={styles.card}>
                        <Paragraph>
                            To stream various sports for free on the website, simply follow these steps:
                        </Paragraph>
                        <Paragraph>
                            Step 1. Open your browser and search for <Link to={'/'}>SportsFeed24</Link>.
                        </Paragraph>
                        <Paragraph>
                            Step 2. Scroll down until you find the section for streaming sports on the website.
                        </Paragraph>
                        <Paragraph>
                            Step 3. Click on the icon of the sport you wish to watch. You will then see all the related information, including event lists, news, updates, important dates, and upcoming matches.
                        </Paragraph>
                        <Paragraph>
                            Step 4. Navigate to the events tab, which will take you to a separate page with multiple links related to the event.
                        </Paragraph>
                        <Paragraph>
                            Step 5. Click on the link for the specific event you want to watch.
                        </Paragraph>
                        <Paragraph>
                            Step 6. Sit back, relax, and enjoy the live stream of your favorite sports.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Which live Sports streams can be watched at Sportsfeed24?' style={styles.card}>
                        {MENU_CATEGORIES?.map((cat: categoryInterface, index: number) => (
                            <Card.Grid key={index} style={gridStyle}>
                            <Link to={'/streams/' + cat.label} >{cat.label}</Link>
                            </Card.Grid>
                        ))}
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Free Live Soccer Streams' style={styles.card}>
                        <Paragraph>
                        Sportsfeed24 streams every important football tournament match, from the English Premier League, Bundesliga, Serie A, Ligue 1, MLS, Saudi Pro League to La Liga. Enjoy uninterrupted viewing of all the prominent matches on our site. Additionally, our streaming service covers matches of renowned football events such as the World Cup, UEFA Cup, Champions Cup, and more. For free live soccer and todays soccer matches, simply refer to the homepage of Sportsfeed24.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Live UFC Matches and Live UFC Streams' style={styles.card}>
                    <Paragraph>
                        Stream the most famous UFC events, including UFC main card on ESPN and other important events like UFC pay-per-view, along with UFC Fight Night events, all on Sportsfeed24.
                    </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Free Live Boxing Streams and Free Live Boxing Matches' style={styles.card}>
                        <Paragraph>
                        At Sportsfeed24, you have access to high-quality games from major boxing organizations like WBA, WBC, and IBF. Find all the live matches related to boxing right on the homepage. Enjoy free live streaming of boxing matches on this platform.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Free live NFL Streams' style={styles.card}>
                        <Paragraph>
                            All sports enthusiasts can access the live stream of NFL games such as the Super Bowl, regular season games, and exciting playoff matches on SportsFeed24. Live NFL matches and NFL live streaming are available on the home page.
                        </Paragraph>
                        <Paragraph>
                            The NFL season 2024 will be starting soon. SportsFeed24 will cover all the matches and fixtures of the NFL season 2024. You can refer to this platform to watch live NFL matches and find NFL live streams on the home page of this live streaming platform.
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Free Live FORMULA 1 or F1' style={styles.card}>
                        <Paragraph>
                            SportsFeed24 streams the F1 World Championship races at no cost. You can find all the F1 live streams and live F1 matches on the home page.
                        </Paragraph>
                    </Card>
                </Col>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Live CRICKET Matches' style={styles.card}>
                        <Paragraph>
                        You can stream all formats of cricket T20, One Day, and Test matches—through SportsFeed24.
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={12}  xs={24} sm={12}>
                    <Card title='Free NBA Matches and Live NBA Streams for Free' style={styles.card}>
                        <Paragraph>
                            Catch the excitement of playoff matches and highly anticipated regular season games of basketball on SportsFeed24. To watch live NBA action and follow every match, head to the homepage of SportsFeed24.
                        </Paragraph>
                    </Card>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col  span={24}  xs={24} sm={24}>
                    <Card title='Reasons why you should choose Sportsfeed24 to watch Live sports streams.' style={styles.card}>
                    <Paragraph>
                        There are several compelling reasons why you should choose SportsFeed24 for streaming and enjoying your favorite sports in the comfort of your own space.
                    </Paragraph>
                    <Paragraph>
                        It is a secure and safe platform for watching sports, without the worry of compromising your privacy.
                    </Paragraph>
                    <Paragraph>
                        We offer high-quality service and live sports streaming at no cost, meaning you do not have to break the bank to watch your favorite sports.
                    </Paragraph>
                    <Paragraph>
                        Your data and identity are safeguarded at all times because protecting your privacy is our top priority.
                    </Paragraph>
                    <Paragraph>
                        Enjoy uninterrupted live streams on your mobile phone, laptop, or computer, all from the comfort of your own home.
                    </Paragraph>
                    <Paragraph>
                        Save time and mental energy by not having to search multiple websites for different sports streams—SportsFeed24 has it all in one place!
                    </Paragraph>
                    <Paragraph>
                        Our goal is to be the premier sports streaming platform, meeting the demands of our users by providing high-quality, uninterrupted sports streaming services.
                    </Paragraph>
                    </Card>
                </Col>
            </Row>
        </Card>
    );
};