import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Button, ConfigProvider, Layout } from 'antd';
import './index.css'

import Menu from '../common/Menu';
import MenuItem from '../common/MenuItem';
// import logo from '../../assets/logo/logo.webp';
import DrawerComponent from '../common/Drawer';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { ConfigConsumerProps } from 'antd/lib/config-provider';
import { MailOutlined, MenuOutlined } from '@ant-design/icons';
import { LOGO_URL, MENU_CATEGORIES, SPORTS_CATEGORIES } from '../../utils/constant';
// import { getAllCategories } from '../../apis/fixture.api';

const { Header } = Layout;

const styles = {
  headerHamburger: (themeConfig: ConfigConsumerProps) => ({
    color: themeConfig?.theme?.token?.colorPrimary,
  }),
  headerDrawer: {
    width: 'auto',
    borderRight: 'none',
  },
};

export default function HeaderComponent() {
  const themeConfiguration = useContext(ConfigProvider.ConfigContext);
  const [headerDrawerOpen, setHeaderDrawerOpen] = useState<boolean>(false);
  const matches = useMediaQuery('(min-width: 1000px)');
  const [urlIndex, setUrlIndex] = useState<string[]>([]);
  const location = useLocation();

  function getURLIndex(url: string){
    SPORTS_CATEGORIES.map((category: any, index: number)=>{
      if(url.includes(category.label)){
        setUrlIndex([`${index}`])
      }else {
        setUrlIndex([`${-1}`])
      }
    });
  }

  useEffect(() => {
    getURLIndex(location.pathname);
  }, [location]);


  const MenuItems = (
    <>
      {MENU_CATEGORIES?.map((cat: any, index: any) => (
      <MenuItem key={index}>
        <Link className='menu-items' to={'/streams/' + cat.value} >{cat.label.toUpperCase()}</Link>
      </MenuItem>
      ))}
    </>
  );

  return (
    <Header
    style={{display: 'flex', justifyContent: 'space-between'}}
    >
      <div
        className='header-body'
      >
        <Link to='/'>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center', gap: '20px' }}>
            {matches && <img className='logo-img' src={LOGO_URL} alt='website-logo' />}
            {!matches && <div onClick={() => setHeaderDrawerOpen(true)}>
            <MenuOutlined style={styles.headerHamburger(themeConfiguration)} />
            </div>}
            <h1 className={matches ? 'site-title': 'site-title-mobile'}>Sports<span style={{color: '#34cfab'}}>Feed</span>24</h1>
          </div>
        </Link>
        <div aria-hidden="true" className='traingle'></div>
        {matches && <Menu mode='horizontal' defaultSelectedKeys={urlIndex} style={{ width: '100%', background: '#286c64' }}>
          {MenuItems}
        </Menu>}
      </div>
      <div style={{display: 'flex', justifyContent: 'right'}}>
        <Link to='/contact'>
          {matches ? 
          <Button shape="round" style={{background: '#000000', color: '#ffffff'}}>
          Contact Us
          </Button>:
          <MailOutlined style={{ color: '#f0f0f0' }}></MailOutlined>}
        </Link>
      </div>
      <DrawerComponent
        title='Sportsfeed24'
        open={headerDrawerOpen}
        onClose={() => setHeaderDrawerOpen(false)}
      >
        {
          <Menu
            onClick={() => setHeaderDrawerOpen(false)}
            mode='vertical'
            defaultSelectedKeys={['2']}
            style={styles.headerDrawer}
          >
            {MenuItems}
          </Menu>
        }
      </DrawerComponent>
    </Header>
  );
}
