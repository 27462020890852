import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { updateStream } from '../../apis/fixture.api';
import { MESSAGES } from '../../utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import Loader from '../common/Loader';

export default function UpdateStream() {
    const matches = useMediaQuery('(min-width: 768px)');
    const navigate = useNavigate();
    const { teams } = useParams();
    const [loader, setLoader] = useState<boolean>(false);

    const updateStreams = async () => {
        try {
            setLoader(true);
            let teamA: string | undefined = teams;
            let teamB: string | undefined = '';
            if(teamA && teamA.includes('-vs-')){
                const teamsArr = teamA.split('-vs-');
                teamA = teamsArr[0];
                teamB = teamsArr[1];
            }
            const payload = {
                teamA: teamA,
                teamB: teamB,
            }
            await updateStream(payload);
            notification.open({
                message: MESSAGES.UPDATE_STREAM_SUCCESS
            });
            navigate(`/fixture/${teams}`)
        } catch (error) {
            notification.open({
                message: MESSAGES.UPDATE_STREAM_FAILURE
            });
        }
        setLoader(false);
    };

    useEffect(() => {
        updateStreams()
      }, []);

    return (
        <>
        <div style={{width: matches ? '1000px': '350px', height: matches ? '650px': '350px'}}>
        {loader ? <Loader /> : <></>}
        </div>
        </>
    );
};