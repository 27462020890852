// import { useEffect } from 'react';
import { ADS } from '../../utils/constant';

declare global {
  interface Window {
    adsbygoogle: unknown[]
  }
}

interface AdSenseProps {
  slot: string;
  type: string;
}

function AdSenseBanner({ slot, type }: AdSenseProps) {

    // useEffect(() => {
    
    //     const scriptElement: any = document.querySelector(
    //       'script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1923488996439217"]'
    //     );
    
    //     const handleScriptLoad = () => {
    //         if (window.adsbygoogle) {
    //           window.adsbygoogle.push({});
    //         } else {
    //           scriptElement.addEventListener('load', handleScriptLoad);
    //         }
    //     };
    
    //     handleScriptLoad();
    //     // Wait for script to load
    
    //     return () => {
    //       if (scriptElement) {
    //         scriptElement.removeEventListener('load', handleScriptLoad);
    //       }
    //     };
    //   }, [slot]);

  return (
    <div key={slot} style={{marginTop: '5px', marginBottom: '10px' }}>
        {type === ADS.MAIN.TYPE && 
        <div style={{ textAlign: 'center' }}>
          <ins className='adsbygoogle'
          style={{ display:'block'}}
          data-ad-client='ca-pub-1923488996439217'
          data-ad-slot={slot}
          data-ad-format='auto'
          data-full-width-responsive='true'></ins>
        </div>
        }
        {type === ADS.IN_FEED.TYPE && <ins 
          className="adsbygoogle"
          style={{display:'block'}}
          data-ad-format="fluid"
          data-ad-layout-key="-6t+ed+2i-1n-4w"
          data-ad-client="ca-pub-1923488996439217"
          data-ad-slot={slot}>
        </ins>
        }
        {type === ADS.IN_ARTICLE.TYPE && <ins className="adsbygoogle"
            style={{display:'block', textAlign:'center'}}
            data-ad-layout="in-article"
            data-ad-format="fluid"
            data-ad-client="ca-pub-1923488996439217"
            data-ad-slot={slot}></ins>
        }
        {(
          type === ADS.PLAYER_DISPLAY_HORIZONTAL.TYPE || 
          type === ADS.FIXTURE_LIST_DISPLAY_HORIZONTAL.TYPE ||
          type === ADS.VERTICLE_MAIN.TYPE ||
          type === ADS.SQUARE_DISPLAY.TYPE
          ) 
        && <ins className="adsbygoogle"
          style={{ display:'block' }}
          data-ad-client="ca-pub-1923488996439217"
          data-ad-slot={slot}
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
        }
    </div>
  )
}

export default AdSenseBanner